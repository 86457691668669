<script setup lang="ts">
import { useSlots } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PrimeModalLayout } from '@primeinsightsgroupllc-modals/prime-modals';
const slots = useSlots();
</script>

<template>
	<PrimeModalLayout class="heycash-modal">
		<template #title>
			<PrimeText
				v-if="slots.header"
				tag="p"
				size="base"
				weight="500"
				color="inherit"
				class="content-header"
			>
				<slot name="header" />
			</PrimeText>
		</template>
		<template #content>
			<span v-if="slots.icon" class="content-icon">
				<slot name="icon" />
			</span>

			<div v-if="slots.title" class="content-title">
				<PrimeText tag="p" weight="500">
					<slot name="title" />
				</PrimeText>
			</div>

			<PrimeText
				v-if="slots.description"
				tag="p"
				size="sm"
				color="grey-700"
				weight="500"
				class="content-description"
			>
				<slot name="description" />
			</PrimeText>

			<div v-if="slots.message" class="content-message">
				<PrimeText tag="p" size="sm" color="grey-600" weight="500">
					<slot name="message" />
				</PrimeText>
			</div>

			<div v-if="slots.inputs" class="content-inputs">
				<slot name="inputs" />
			</div>

			<slot name="default" />
		</template>

		<template v-if="slots.actions || slots.raw" #bottom>
			<div
				v-if="slots.actions"
				class="content-actions"
				:class="{
					'inputs-actions': slots.inputs,
				}"
			>
				<slot name="actions" />
			</div>

			<div v-if="slots.raw">
				<slot name="raw" />
			</div>
		</template>
	</PrimeModalLayout>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.heycash-modal {
	.content-icon {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.content-icon img {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		height: auto;
	}

	.content-icon svg {
		width: 3.375rem;
		height: 3.375rem;
		color: white;

		@include breakpoint(mobile) {
			width: 5.875rem;
			height: 5.875rem;
		}
	}

	.content-title {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-bottom: 0.25rem;
		text-align: center;

		.p-text {
			max-width: 85%;

			@include breakpoint(mobile) {
				max-width: 75%;
			}
		}
	}

	.content-description {
		border-bottom: 1px solid var(--grey-200);
		margin-bottom: 0.75rem;
		padding-bottom: 0.75rem;
		width: 100%;
	}

	.content-message {
		text-align: center;
		width: 100%;
		display: flex;
		justify-content: center;
		.p-text {
			max-width: 85%;

			@include breakpoint(mobile) {
				max-width: 70%;
			}
		}
	}

	.content-inputs {
		width: 100%;

		& .p-input-wrapper:not(:first-of-type) {
			margin-top: 0.75rem;
		}
	}

	.content-actions {
		width: 100%;

		& .button {
			width: 100%;
			padding: 0.875rem;
		}
	}
}
</style>
