<script lang="ts" setup>
import type { Modal } from '@/types';
import type { Component } from 'vue';
import { watch, ref, onMounted, onBeforeUnmount } from 'vue';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useDocumentVisibility } from '@vueuse/core';
import { useMessagesStore } from '@/stores/messages';
import {
	USER_LEVEL_MODAL,
	USER_STREAK_MODAL,
	WELCOME_BONUS_MODAL,
	REWARD_SENT_MODAL,
	LEADERBOARD_MODAL,
	PRIZE_DRAW_MODAL,
	NEW_PASSWORD_SENT,
	ONE_CLICK_SURVEY_MODAL,
	LEADERBOARD_WEEKLY_MODAL,
	SELECT_COUNTRY_LANGUAGE_MODAL,
	SET_USER_PASSWORD_MODAL,
	NEW_LEVELS_MODAL,
	MOBILE_APP_HERE_MODAL,
	CONFIRM_CLAIM_DATA_MODAL,
	CONFIRM_CLAIM_MODAL,
	GOOGLE_TRANSLATE_MODAL,
	PRIZE_BONUS_MODAL,
	REVOLUT_ANNOUNCEMENT_MODAL,
	REFERRALS_ANNOUNCEMENT_MODAL,
	ASK_TRACKING_PERMISSIONS_MODAL,
	OFFERWALLS_ONETIME_NOTIFICATION_MODAL,
	OFFERWALLS_REWARD_NOTIFICATION_MODAL,
	IOS_BROKEN_APP_MODAL,
	DYNAMIC_ANNOUNCEMENT_MODAL,
	UNLOCK_OFFERWALLS_MODAL,
	UPDATE_AVAILABLE_MODAL,
	OFFERWALLS_WELL_DONE_MODAL,
	OFFERWALLS_PENDING_MODAL,
} from '@/constants/modals';
import ModalWrapper from '@/components/common/ModalWrapper.vue';
import UserCongratsLevelModal from '@/components/modals/CongratsLevelModal.vue';
import UserCongratsStreakModal from '@/components/modals/CongratsStreakModal.vue';
import WelcomeBonusModal from '@/components/modals/WelcomeBonusModal.vue';
import RewardSentModal from '@/components/modals/RewardSentModal.vue';
import LeaderboardModal from '@/components/modals/LeaderboardModal.vue';
import PrizeDrawModal from '@/components/modals/PrizeDrawModal.vue';
import NewPasswordSentModal from '@/components/modals/NewPasswordSentModal.vue';
import OneClickSurveyModal from '@/components/modals/OneClickSurveyModal.vue';
import LeaderboardWeeklyModal from '@/components/modals/LeaderboardWeeklyModal.vue';
import SelectCountryLanguageModal from '@/components/modals/SelectCountryLanguageModal.vue';
import SetUserPasswordModal from '@/components/modals/SetUserPasswordModal.vue';
import NewLevelsModal from '@/components/modals/NewLevelsModal.vue';
import MobileAppHereModal from '@/components/modals/MobileAppHereModal.vue';
import ConfirmPaypalEmailModal from '@/components/modals/ConfirmClaimDataModal.vue';
import ConfirmClaimModal from '@/components/modals/ConfirmClaimModal.vue';
import GoogleTranslateModal from '@/components/modals/GoogleTranslateModal.vue';
import PrizeBonusModal from '@/components/modals/PrizeBonusModal.vue';
import RevolutAnnouncementModal from '@/components/modals/announcements/RevolutAnnouncementModal.vue';
import ReferralsAnnouncementModal from '@/components/modals/announcements/ReferralsAnnouncementModal.vue';
import { MessageChannels } from '@/enums';
import AskTrackingPermissionsModal from '@/components/modals/AskTrackingPermissionsModal.vue';
import OfferwallsOnetimeNotificationModal from '@/components/modals/notifications/OfferwallsOnetimeNotificationModal.vue';
import OfferwallRewardNotificationModal from '@/components/modals/notifications/OfferwallRewardNotificationModal.vue';
import IosBrokenAppModal from '@/components/modals/IosBrokenAppModal.vue';
import DynamicAnnouncementModal from '@/components/modals/announcements/DynamicAnnouncementModal.vue';
import UnlockOfferwallsModal from '@/components/modals/UnlockOfferwallsModal.vue';
import UpdateAvailableModal from '@/components/modals/UpdateAvailableModal.vue';
import OfferwallsWellDoneModal from '@/components/modals/OfferwallsWellDoneModal.vue';
import OfferwallsPendingModal from '@/components/modals/OfferwallsPendingModal.vue';

const MODAL_MAP: Record<string, Component> = {
	[USER_LEVEL_MODAL]: UserCongratsLevelModal,
	[USER_STREAK_MODAL]: UserCongratsStreakModal,
	[WELCOME_BONUS_MODAL]: WelcomeBonusModal,
	[REWARD_SENT_MODAL]: RewardSentModal,
	[LEADERBOARD_MODAL]: LeaderboardModal,
	[PRIZE_DRAW_MODAL]: PrizeDrawModal,
	[NEW_PASSWORD_SENT]: NewPasswordSentModal,
	[ONE_CLICK_SURVEY_MODAL]: OneClickSurveyModal,
	[LEADERBOARD_WEEKLY_MODAL]: LeaderboardWeeklyModal,
	[SELECT_COUNTRY_LANGUAGE_MODAL]: SelectCountryLanguageModal,
	[SET_USER_PASSWORD_MODAL]: SetUserPasswordModal,
	[NEW_LEVELS_MODAL]: NewLevelsModal,
	[MOBILE_APP_HERE_MODAL]: MobileAppHereModal,
	[CONFIRM_CLAIM_DATA_MODAL]: ConfirmPaypalEmailModal,
	[CONFIRM_CLAIM_MODAL]: ConfirmClaimModal,
	[GOOGLE_TRANSLATE_MODAL]: GoogleTranslateModal,
	[PRIZE_BONUS_MODAL]: PrizeBonusModal,
	[REVOLUT_ANNOUNCEMENT_MODAL]: RevolutAnnouncementModal,
	[REFERRALS_ANNOUNCEMENT_MODAL]: ReferralsAnnouncementModal,
	[ASK_TRACKING_PERMISSIONS_MODAL]: AskTrackingPermissionsModal,
	[OFFERWALLS_ONETIME_NOTIFICATION_MODAL]: OfferwallsOnetimeNotificationModal,
	[OFFERWALLS_REWARD_NOTIFICATION_MODAL]: OfferwallRewardNotificationModal,
	[IOS_BROKEN_APP_MODAL]: IosBrokenAppModal,
	[DYNAMIC_ANNOUNCEMENT_MODAL]: DynamicAnnouncementModal,
	[UNLOCK_OFFERWALLS_MODAL]: UnlockOfferwallsModal,
	[UPDATE_AVAILABLE_MODAL]: UpdateAvailableModal,
	[OFFERWALLS_WELL_DONE_MODAL]: OfferwallsWellDoneModal,
	[OFFERWALLS_PENDING_MODAL]: OfferwallsPendingModal,
};

const messageStore = useMessagesStore();
const visibility = useDocumentVisibility();
const { shiftModal, storage, activeModal } = useModalStorage();
const modal = ref<Modal | undefined>(undefined);
const showModal = ref(false);
const timeout = ref<any>(null);

const sendReadEvent = async () => {
	if (modal.value?.options?.id && !modal.value?.options?.achievement_key) {
		switch (modal.value?.options?.channel) {
			case MessageChannels.NOTIFICATION:
				await messageStore.readNotification(modal.value.options.id);
				break;
			case MessageChannels.ANNOUNCEMENT:
				await messageStore.readAnnouncement(modal.value.options.id);
				break;
			default:
				break;
		}
	}
};

const handleCloseModal = async () => {
	if (visibility.value === 'visible') {
		try {
			await sendReadEvent();
		} finally {
			await shiftModal();
		}
		try {
			await sendReadEvent();
		} finally {
			await shiftModal();
		}
	}
};

onMounted(async () => {
	if (
		visibility.value === 'visible' &&
		!Object.keys(activeModal.value).length &&
		storage.value.length
	) {
		await shiftModal();
	}
});

watch(
	() => storage.value,
	async (value) => {
		if (visibility.value === 'visible' && value.length && !modal.value) {
			await shiftModal();
		}
	}
);

watch(visibility, async (value) => {
	if (value === 'visible' && !modal.value && storage.value.length) {
		await shiftModal();
	}
});

watch(
	activeModal,
	(value) => {
		if (Object.keys(value).length) {
			modal.value = { ...value };
			showModal.value = Boolean(modal.value);
		} else {
			showModal.value = false;

			if (modal.value?.options?.position === 'bottom') {
				// Set timeout for transition duration on close
				timeout.value = setTimeout(() => {
					modal.value = undefined;
					clearTimeout(timeout.value);
				}, 550);
			} else {
				modal.value = undefined;
			}
		}
	},
	{ immediate: true }
);

onBeforeUnmount(() => {
	clearTimeout(timeout.value);
});
</script>

<template>
	<ModalWrapper
		:show="showModal"
		:use-default-close="modal?.options?.useDefaultClose ?? true"
		:position="modal?.options?.position"
		:data-test-id="modal?.name || ''"
		:channel="modal?.options?.channel"
		@close-modal="handleCloseModal"
	>
		<component
			:is="{ ...MODAL_MAP[modal.name] }"
			v-if="modal?.name"
			:open="showModal"
			:options="modal.options"
			:data="modal.data"
			@close-modal="handleCloseModal"
		/>
	</ModalWrapper>
</template>
