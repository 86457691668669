<script setup lang="ts">
//TODO add this component in to Prime Ui Kit
import { computed } from 'vue';

export type PrimeProgressChartProps = {
	percent: number;
	size?: number;
	strokeWidth?: number;
};

const {
	percent = 0,
	size = 120,
	strokeWidth = 10,
} = defineProps<PrimeProgressChartProps>();

const radius = computed(() => (size - strokeWidth * 2) / 2);
const circumference = computed(() => 2 * Math.PI * radius.value);
const filledLength = computed(() => (circumference.value * percent) / 100);
const unfilledLength = computed(() => circumference.value - filledLength.value);
const halfSize = computed(() => size / 2);
</script>

<template>
	<div class="p-chart-container">
		<svg :width="size" :height="size">
			<circle
				:cx="halfSize"
				:cy="halfSize"
				:r="radius"
				class="p-chart-back"
			></circle>
			<circle
				v-if="percent"
				:cx="halfSize"
				:cy="halfSize"
				:r="radius"
				class="p-chart-front"
				:stroke-dasharray="`${filledLength} ${unfilledLength}`"
			></circle>
		</svg>
		<div
			class="p-chart-slot"
			:style="{ padding: `calc(${strokeWidth}px + 20px)` }"
		>
			<slot />
		</div>
	</div>
</template>

<style scoped>
.p-chart-container {
	display: inline-block;
	position: relative;
}
.p-chart-slot {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.p-chart-back {
	stroke: var(--extra-grey-900);
	stroke-width: v-bind(strokeWidth);
	fill: none;
}

.p-chart-front {
	stroke: rgba(255, 185, 69, 1);
	stroke-width: v-bind(strokeWidth);
	stroke-linecap: round;
	transform: rotate(-90deg);
	transform-origin: center;
	fill: none;
	transition: stroke-dasharray 0.5s ease-in-out;
}
</style>
