export const THEME_DARK_APP = {
	'primary-base': '#3BBA6A',
	'secondary-base': '#3BBA6A',
	'text-default': '#AEB2BC',
	'text-disabled': '#ffffff4d',
	'state-success': '#3BBA6A',
	'grey-50': '#24262B',
	'grey-100': '#363840',
	'grey-200': '#595E6A',
	'grey-300': '#363840',
	'grey-400': '#363840',
	'grey-500': '#28292B',
	'grey-600': '#475467',
	'grey-700': '#AEB2BC',
	'grey-800': '#303133',
	white: '#fff',
	'radius-rounded': '0.75rem',
	'primary-font-family': 'Euclid Circular A',
	'secondary-font-family': 'Montserrat',
	'shadow-base':
		'0px 1px 3px 0px rgba(63, 63, 68, 0.15), 0px 0px 0px 1px rgba(63, 63, 68, 0.05)',
};

export const THEME_ICONS_DARK_APP = {
	'base-primary': '#3BBA6A',
	'base-secondary': '#FFB945',
	'text-default': '#AEB2BC',
	'text-disabled': '#ffffff4d',
};

export const THEME_PALETTE = 'dark';
