import type { Component } from 'vue';
import {
	DELETE_ACCOUNT_MODAL,
	CHANGE_USERNAME_MODAL,
	CHANGE_TIMEZONE_MODAL,
	CHANGE_EMAIL_MODAL,
	CHANGE_PASSWORD_MODAL,
	BONUS_CODE_MODAL,
	DAILY_STREAK_MODAL,
	REWARDS_MODAL,
	CHANGE_LANGUAGE_MODAL,
	REFFERAL_LINK_MODAL,
} from '@/constants/modals';

import DeleteAccountModal from '@/components/modals/DeleteAccountModal.vue';
import ChangeUsernameModal from '@/components/modals/ChangeUsernameModal.vue';
import ChangeTimezoneModal from '@/components/modals/ChangeTimezoneModal.vue';
import ChangeEmailModal from '@/components/modals/ChangeEmailModal.vue';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal.vue';
import BonusCodeModal from '@/components/modals/BonusCodeModal.vue';
import DailyStreak from '@/components/common/header/DailyStreak.vue';
import RewardsModal from '@/components/modals/RewardsModal.vue';
import ChangeLanguageModal from '@/components/modals/ChangeLanguageModal.vue';
import RefferalLinkModal from '@/components/modals/RefferalLinkModal.vue';

export const MODAL_MAP: Record<string, Component> = {
	[DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
	[CHANGE_USERNAME_MODAL]: ChangeUsernameModal,
	[CHANGE_TIMEZONE_MODAL]: ChangeTimezoneModal,
	[CHANGE_EMAIL_MODAL]: ChangeEmailModal,
	[CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
	[BONUS_CODE_MODAL]: BonusCodeModal,
	[DAILY_STREAK_MODAL]: DailyStreak,
	[REWARDS_MODAL]: RewardsModal,
	[CHANGE_LANGUAGE_MODAL]: ChangeLanguageModal,
	[REFFERAL_LINK_MODAL]: RefferalLinkModal,
};
