export const rewardItemsWithWhiteBg: string[] = [
	'PayPal',
	"Zaxby's",
	'Victoria’s Secret PINK',
	'Victoria’s Secret',
	'The Vitamin Shoppe®',
	'Red Lobster',
	'Razer Gold USD',
	'Publix',
	'Moe’s Southwest Grill',
	'Lyft US',
	'Lululemon US',
	'Lego',
	"Joe's Crab Shack",
	'KFC',
	'Hard Rock Cafe',
	'Genshin Impact',
	'FreeFire USD',
	'Etsy',
	'Disney',
	"Cabela's®",
	'Bed Bath & Beyond',
  'PayPal International',
  'Apple'
];

export const REWARD_CATEGORY_WITH_WHITE_BG = 4;
