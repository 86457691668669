<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { localizePoints } from '@/utils/helpers';
import { LEVEL, COLLECT_MORE_POINTS } from '@/locales/constants';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import UserlevelInfoCard from './UserlevelInfoCard.vue';
import PrimeProgressChart from '../PrimeProgressChart.vue';

const { levelActual, coinsEarned, coinsTarget, levelReward } =
	storeToRefs(useUserStore());

const progress = computed(
	() => (Number(coinsEarned.value) / Number(coinsTarget.value)) * 100
);
const morePoints = computed(() => coinsTarget.value - coinsEarned.value);
</script>
<template>
	<div class="user-level-large">
		<PrimeProgressChart :percent="progress">
			<div class="chart-content">
				<PrimeText color="white" size="xl" weight="600">
					{{ levelActual }}
				</PrimeText>
				<PrimeText>{{ $t(LEVEL) }}</PrimeText>
			</div>
		</PrimeProgressChart>
		<div class="user-level-points">
			<PrimeText size="xl" weight="600" color="secondary">
				{{ localizePoints(coinsEarned) }}
			</PrimeText>
			<PrimeText size="xl" weight="600" color="white">
				/ {{ localizePoints(coinsTarget) }}
			</PrimeText>
		</div>
		<PrimeText
			align="center"
			class="collect-more-text"
			color="grey-200"
			v-html="$t(COLLECT_MORE_POINTS, { value: localizePoints(morePoints) })"
		/>
		<UserlevelInfoCard :points="levelReward" />
	</div>
</template>

<style scoped lang="scss">
.user-level-large {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2.25rem 0.875rem;
	background: var(--extra-bg);
	border: 1px solid var(--extra-grey-900);
	border-radius: 1.5rem;

	.chart-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.user-level-points {
		display: flex;
		justify-content: center;
		margin: 1.5rem 0 0.5rem;
	}

	.collect-more-text {
		text-align: center;
		display: inline-block;
		:deep(span) {
			font-weight: 500;
			color: #fff;
		}
	}

	.user-level-info {
		margin-top: 0.5rem;
	}
}
</style>
