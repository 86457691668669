<script setup lang="ts">
import { useSlots } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const slots = useSlots();
</script>
<template>
	<div class="modal-common-content">
		<PrimeText
			v-if="slots.header"
			tag="p"
			size="base"
			weight="500"
			color="inherit"
			class="content-header"
		>
			<slot name="header" />
		</PrimeText>

		<span v-if="slots.icon" class="content-icon">
			<slot name="icon" />
		</span>

		<PrimeText v-if="slots.title" tag="p" weight="500" class="content-title">
			<slot name="title" />
		</PrimeText> 

		<PrimeText
			v-if="slots.description"
			tag="p"
			size="sm"
			color="grey-700"
			weight="500"
			class="content-description"
		>
			<slot name="description" />
		</PrimeText>

		<PrimeText
			v-if="slots.message"
			tag="p"
			size="sm"
			color="grey-600"
			weight="500"
			class="content-message"
		>
			<slot name="message" />
		</PrimeText>

		<div v-if="slots.inputs" class="content-inputs">
			<slot name="inputs" />
		</div>

		<slot name="default" />

		<div
			v-if="slots.actions"
			class="content-actions"
			:class="{
				'inputs-actions': slots.inputs,
			}"
		>
			<slot name="actions" />
		</div>

		<div v-if="slots.raw">
			<slot name="raw" />
		</div>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.modal-common-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	max-height: 80vh;

	.content-header {
		padding: 0 2.5rem 1.25rem 0.875rem;
		width: calc(100% + 1.25rem);
		text-align: left;

		@include breakpoint(mobile) {
			padding-left: 1.5rem;
			padding-bottom: 1.5rem;
			width: calc(100% + 3rem);
		}
	}

	.content-icon {
		margin-bottom: 1.375rem;
	}

	.content-icon img {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		height: auto;
	}

	.content-icon,
	.content-icon svg {
		width: 3.375rem;
		height: 3.375rem;
		color: white;

		@include breakpoint(mobile) {
			width: 5.875rem;
			height: 5.875rem;
		}
	}

	.content-title {
		margin-bottom: 0.25rem;
		max-width: 85%;

		@include breakpoint(mobile) {
			max-width: 75%;
		}
	}

	.content-description {
		border-bottom: 1px solid var(--grey-200);
		margin-bottom: 0.75rem;
		padding-bottom: 0.75rem;
		width: 100%;
	}

	.content-message {
		max-width: 85%;

		@include breakpoint(mobile) {
			max-width: 70%;
		}
	}

	.content-inputs {
		width: 100%;
		margin-top: 0.75rem;

		& .p-input-wrapper:not(:first-of-type) {
			margin-top: 0.75rem;
		}
	}

	.content-actions {
		margin-top: 2.125rem;
		width: 100%;

		&.inputs-actions {
			margin-top: 1.375rem;
		}

		& .button {
			width: 100%;
			padding: 0.875rem;
		}
	}
}
</style>
