<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRewardsStore } from '@/stores/rewards';
import { useUserStore } from '@/stores/user';
import { Platforms, type RewardCategories } from '@/enums';
import {
	NO_REWARDS_MESSAGE,
	NO_REWARDS_TITLE,
	NO_REWARDS_WITH_NAME,
	SEARCH_REWARDS,
	SELECT,
	SELECT_REWARDS,
} from '@/locales/constants';
import type { RewardsItem } from '@/types';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import RewardsCategory from '@/views/surveys/components/user-dashboard/rewards/RewardsCategory.vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	PrimeButton,
	PrimeSkeleton,
	PrimeDivider,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import InputSearch from '@/components/common/InputSearch.vue';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { storeToRefs } from 'pinia';
import PromotedRewards from '@/views/surveys/components/user-dashboard/rewards/PromotedRewards.vue';
import { RewardCategories as Categories } from '@/enums';
import { useRouter } from 'vue-router';
import { USER_ROUTE_NAME } from '@/constants/routes';
import RewardInfo from '@/views/surveys/components/user-dashboard/rewards/RewardInfo.vue';
import { PrimeModalLayout } from '@primeinsightsgroupllc-modals/prime-modals';

const { open } = defineProps<{
	open: boolean;
}>();

const emit = defineEmits<{
	'close-modal': [];
}>();

const { platform } = useMobileApp();
const { t } = useI18n();
const rewardsStore = useRewardsStore();
const {
	rewardsBySubCategory,
	rewardByCategories,
	isLoading,
	rewardsError,
	categoriesWithSubCategories,
} = storeToRefs(rewardsStore);
const userStore = useUserStore();
const searchValue = ref('');
const selectedRewardOptionId = ref(0);
const router = useRouter();
const modalContainerRef = ref<HTMLElement | null>(null);
const modalContaineMinHeight = ref(0);

const handleCloseModal = () => {
	emit('close-modal');
};

onMounted(async () => {
	selectedRewardOptionId.value = 0;

	if (open) {
		await rewardsStore.fetchRewards();
	}

	modalContaineMinHeight.value = modalContainerRef.value?.offsetHeight || 0;
});

const filteredRewards = computed<Record<
	RewardCategories,
	RewardsItem[]
> | null>(() => {
	if (rewardByCategories.value && searchValue.value) {
		const filteredItems = {} as Record<string, RewardsItem[]>;
		for (const [key, value] of Object.entries(rewardByCategories.value)) {
			filteredItems[key] = value.filter((item) =>
				item.name.toLowerCase().includes(searchValue.value.toLowerCase())
			);
		}
		return filteredItems;
	} else {
		return rewardsBySubCategory.value;
	}
});

const isEmptyFilterRewards = computed(() =>
	filteredRewards.value
		? !Object.values(filteredRewards.value).filter((item) => item.length).length
		: false
);

const emptySearchResult = computed(() => {
	return searchValue.value.length
		? t(NO_REWARDS_WITH_NAME, { value: searchValue.value })
		: t(NO_REWARDS_MESSAGE);
});

const postSelectedReward = async () => {
	if (selectedRewardOptionId.value) {
		const responseStatus = await rewardsStore.selectReward(
			selectedRewardOptionId.value
		);

		if (responseStatus === 422) {
			notify({
				body: rewardsError.value,
			});
			return;
		}
		rewardsStore.setClaimInitialized(true);
		await userStore.fetchUserData();
		selectedRewardOptionId.value = 0;
		handleCloseModal();

		await router.push({ name: USER_ROUTE_NAME.CONFIRM_CLAIM });
	}
};

const setSelectedOption = (event: number) => {
	selectedRewardOptionId.value = event;
};

const footerPaddingStyle = computed(() => {
	return platform.value === Platforms.IOS
		? 'calc(env(safe-area-inset-bottom))'
		: '0';
});
</script>

<template>
	<PrimeModalLayout class="heycash-modal rewards-modal-wrapper">
		<template #title>
			<PrimeText size="base" weight="500" color="inherit">{{
				$t(SELECT_REWARDS)
			}}</PrimeText>
		</template>
		<template #content>
			<div
				ref="modalContainerRef"
				class="rewards-modal-container"
				:style="{ minHeight: modalContaineMinHeight + 'px' }"
			>
				<div class="rewards-modal-top-wrapper">
					<InputSearch
						v-model:search="searchValue"
						:placeholder="$t(SEARCH_REWARDS)"
					/>

					<RewardInfo />
				</div>
				<div v-if="rewardsStore.initLoading" class="rewards-list">
					<PrimeSkeleton
						v-for="i in 7"
						:key="i"
						class="reward-skeleton"
						width="auto"
						height="3.6rem"
					/>
				</div>

				<div v-else-if="!isEmptyFilterRewards" class="rewards-list">
					<template
						v-for="(categoryItems, categoryName) in filteredRewards"
						:key="categoryName"
					>
						<PromotedRewards
							v-if="
								open &&
								categoryItems.length &&
								categoryName === Categories.PROMOTED
							"
							:items="categoryItems"
							:selected-option="selectedRewardOptionId"
							@select-option="setSelectedOption"
						/>
						<RewardsCategory
							v-if="
								categoryItems.length && categoryName !== Categories.PROMOTED
							"
							v-model:selected-option="selectedRewardOptionId"
							:category-items="categoryItems"
							:category="categoryName"
							:is-filtered="!!searchValue.length"
							:sub-categories="
								categoriesWithSubCategories[categoryName] && !searchValue
							"
						/>
					</template>
				</div>

				<div v-else class="fallback-wrapper">
					<PrimeText
						tag="p"
						weight="600"
						size="3xl"
						class="fallback-title"
						color="inherit"
					>
						{{ $t(NO_REWARDS_TITLE) }}
					</PrimeText>
					<PrimeText
						tag="p"
						color="grey-700"
						size="sm"
						class="fallback-message"
					>
						{{
							isEmptyFilterRewards ? emptySearchResult : $t(NO_REWARDS_MESSAGE)
						}}
					</PrimeText>
				</div>

				<PrimeLoader v-if="isLoading" />
			</div>
		</template>
		<template #bottom>
			<div
				class="modal-footer"
				:class="{ 'hide-footer': isEmptyFilterRewards }"
			>
				<PrimeDivider color="grey-100" bottom-gap="1.125rem" />
				<PrimeButton
					full-width
					:label="$t(SELECT)"
					class="select-button"
					:disabled="!selectedRewardOptionId"
					@click="postSelectedReward"
				/>
			</div>
		</template>
	</PrimeModalLayout>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.reward-skeleton {
	margin: 0.75rem 1.5rem;
}

.rewards-list {
	flex: 1;
	padding-top: 0.75rem;
	overflow: auto;
	padding-bottom: 6.875rem;

	&::-webkit-scrollbar {
		width: 0;
	}

	@include breakpoint(mobile) {
		padding-bottom: 5rem;
	}
}

.modal-footer {
	padding-bottom: v-bind(footerPaddingStyle);

	&.hide-footer {
		opacity: 0;
		pointer-events: none;
	}

	.select-button {
		padding: 0.875rem;
	}

	:deep(.p-divider) {
		margin-left: -1rem;
		margin-right: -1rem;
		width: auto;

		@include breakpoint(mobile) {
			margin-left: -1.375rem;
			margin-right: -1.375rem;
		}
	}
}

.fallback-wrapper {
	color: white;
	height: calc(100% - 9.375rem);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 5rem;

	& .fallback-title {
		margin-bottom: 0.125rem;
	}
}

.rewards-modal-wrapper :deep(.p-modal-bottom) {
	padding-top: 0 !important;
}
.rewards-modal-wrapper :deep(.p-modal-content) {
	padding-bottom: 0 !important;
}
.rewards-modal-wrapper.heycash-modal.p-modal-layout {
	max-width: 34.375rem !important;
}
.rewards-modal-wrapper :deep(.category-wrapper) {
	padding: 0;
}
</style>

<style lang="scss">
@import '@/styles/mixins.scss';

.rewards-modal-container {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.rewards-modal-container {
	flex: none;
}

.rewards-list {
	& .reward-category:last-of-type {
		& .category-wrapper {
			margin-bottom: 1.125rem;
		}
		& .category-divider {
			display: none;
		}
	}
}
</style>
